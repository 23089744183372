.dev_five_container {
    display: flex;
    flex-direction: column;
    background-color: #222828;
    color: #dfdfdf;
    font-family: "Roboto", monospace;
}

.dev_five_container p {
    opacity: 0.4;
}

.dev_five_container h3 {
    margin-bottom: 30px;
}

.dev_five_container p {
    margin-top: 30px;
}

.dev_five_container h4 {
    margin-bottom: 20px;
}

.dev-five-yt-player {
    height: 600px;
}