.bg_three_container {
    display: flex;
    flex-direction: column;
    background-color: #222828;
    color: #dfdfdf;
}
.bg_three_para h2 {
    font-family: 'Roboto', monospace;
    opacity: 0.7;
    margin-bottom: 30px;
}
.bg_three_para p{
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', monospace;
    opacity: 0.6;
    margin-bottom: 20px;
    line-height: 24px;
}