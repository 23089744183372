.bg_five_container {
    display: flex;
    flex-direction: column;
    background-color: #222828;
    color: #dfdfdf;
}
.game_details_section h2{
    font-family: "Roboto", monospace;
    opacity: 0.7;
    margin-bottom: 20px;
}
.game_details_section p{
    font-family: "Roboto", monospace;
    opacity: 0.6;
    margin-bottom: 30px;
    line-height: 24px;
}

.bg_five_how_to_play_container {
    font-family: 'Roboto', monospace;
}

.bg_five_how_to_play_container h3 {
    opacity: 0.7;
    margin-bottom: 30px;
}

.bg_five_how_to_play_container p {
    margin-bottom: 30px;
    opacity: 0.6;
    letter-spacing: normal;
    line-height: 24px;
}

.bg_five_how_to_play_container ol {
    padding-left: 4%;
    padding-right: 4%;
    margin-bottom: 30px;
    opacity: 0.6;
}

.bg_five_how_to_play_container li {
    margin-bottom: 10px;
}

.caraousal_heading {
    opacity: 0.7;
    font-family: 'Roboto', monospace;
    letter-spacing: normal;
}