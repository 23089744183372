.about_container{
    background-color: #2C3333;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2% 7% ;
}

.about_right{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 600px;
    background-color: #222828;
    border-radius: 2%;
    height: 400px;
    overflow: hidden;
}

.about_left {
    font-family: 'Roboto', monospace;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.about_text_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 20px;
}

.about_section_heading{
    font-size: 34px;
    color: #dfdfde;
    opacity: 0.9;
}

.about_section_description{
    color: #dfdfde;
    opacity: 0.6;
    font-size: 14px;
    line-height: 25px;
}

.chat_button {
    font-family: 'Roboto', monospace;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    width: 100px;
    background-color: #2C3333;
    border: none;
    color: #03c988;
    text-decoration: underline;
    letter-spacing: normal;
    font-size: 14px;
    cursor: pointer;
    margin: 10px 0px;
}

.highlighted_text_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1rem;
    width: 100%;
}

.highlighted_text {
    
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #03c988;
    font-family: 'Roboto', monospace;
    font-size: 14px;
    border-radius: 2px;
    font-weight: 700;
    letter-spacing: 1px;
    height: 20px;
    padding: 0 1rem;
}

.stats_section {
    display: flex;
    flex-direction: row;
}

.stat_text_section {
    font-family: 'Roboto', monospace;
    display: flex;
    flex-direction: row;
    width: 200px;
    column-gap: 10px;
}
 
.ts_two {
    width: 400px;
}
.number{
    display: flex;
    align-items: center;
    color: #dfdfde;
    opacity: 0.9;
    font-size: 32px;
}

.text {
    display: flex;
    align-items: center;
    color: #dfdfde;
    opacity: 0.6;
    font-size: 12px;
    line-height: 17px;
}

.profile_pic {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@media (max-width: 720px) {
    .about_right {
        
        width: 350px;
    }
    .about_left {
        width: 350px;
    }
    .profile_pic {
        
        width: 300px;
    }
    .about_section_heading {
        font-size: 24px;
    }
    .stats_section {
        width: 250px;
    }
    .number {
        font-size: 24px;
    }
    .chat_button {
        font-size: 12px;
    }
    .text {
        line-height: 15px;
    }
}

@media only screen and (min-width : 1080px) and (max-width: 1400px){
    .about_right {
        
        width: 350px;
    }
    .about_left {
        width: 550px;
    }
    .about_section_heading{
        font-size: 26px;
    }
    .profile_pic {
        width: 350px;
    }
    .stats_section {
        width: 350px;
    }
    .stat_text_section {
        justify-content: flex-start;
    }
    .number {
        font-size: 24px;
    }
    .chat_button {
        font-size: 12px;
    }
    .text {
        line-height: 15px;
    }
}

@media only screen and (min-width: 2100px) {
    .about_container {
        justify-content: space-between;
        padding: 2% 15%;
    }
}