.prototype_section_container {
    background-color: #2c3333;
}

.prototype_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3% 7%;
}

.prot_heading_section {
    width: 100%;
    font-family: 'Roboto', monospace;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.prot_section_heading_text {
    color: #dfdfdf;
    opacity: 0.9;
    font-size: 36px;
    
}

.prot_section_description_text {
    color: #dfdfdf;
    opacity: 0.7;
    line-height: 24px;
    width: 60%;
}

@media only screen and (min-width : 2560px) {
    .prototype_container {
        padding: 2% 15%;
    }
}