.work_container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #2C3333;
    padding: 3% 7%;
    row-gap: 2rem;
}

.work_card {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    cursor: pointer;
    gap: 2rem;
}

.text_section {
    font-family: 'Roboto', monospace;
    width: 700px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    background-color: #222828;
    height: 210px;
    padding: 3%; 
    border-radius: 6px;
}

.work_image {
    height: 100%;
    width: 100%;
    border-radius: 5px;
    object-fit: cover;
}

.work_image img{
    width: 100%;
    height: 100%;
}

.card_heading {
    color: #dfdfde;
    opacity: 0.9;
    font-size: 20px;
    font-weight: 400;
}

.card_description {
    color: #dfdfde;
    opacity: 0.6;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: normal;
}

@media only screen and (max-width : 1080px) {
    .work_image {
        height: 240px;
        width: 350px;
    }
    .text_section {
        height: 182px;
        width: 500px;
    }
}

@media only screen and (min-width : 1220px) {
    .work_image {
        height: 285px;
        width: 450px;
    }
    .work_card {
        margin-top: -10px;
    }
}


@media only screen and (min-width : 1920px) {
    .work_container {
        justify-content: space-between;
    }
    .work_image {
        height: 310px;
        width: 690px;
    }
    .text_section {
        width: 820px;
    }
}

@media only screen and (min-width : 2100px) {
    .work_container {
        justify-content: space-between;
        padding: 2% 15%;
    }
    .work_image {
        height: 295px;
        width: 630px;
    }
    .work_card {
        margin-bottom: 20px;
    }
}

@media only screen and (min-width : 2560px) {
    .work_container {
        justify-content: space-between;
        padding: 2% 15%;
    }
    .work_image {
        height: 317.5px;
        width: 770px;
    }
    .text_section {
        
        width: 860px;
    }
}