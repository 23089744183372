.vrthree_container {
    display: flex;
    flex-direction: column;
    background-color: #222828;
    color: #dfdfdf;
    font-family: "Roboto", monospace;

}

.main_section a {
    text-decoration: none;
    background-color: #03c988;
    border-radius: 10px;
    text-align: center;
    padding: 1rem;
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    color: #222828;
    border: 1px solid #03c988;
    transition: 0.3s ease;
    cursor: pointer;
}

.main_section a:hover{
    background-color: #222828;
    border: 1px solid #03c988;
    color: #03c988;
    transition: 0.3s ease;
}

.head_toe_details_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2rem;
}

.head_toe_details span {
    font-weight: 700;
    color: #f8f8f8;
}

.head_toe_details_container h3{
    font-size: 24px;
    opacity: 1;
}

.head_toe_details_container h4{
    font-size: 20px;
    opacity: 1;
}

.head_toe_details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    
}

.head_toe_details ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.5rem;
    padding: 0 1rem;
}

.head_toe_details li{
    
    font-size: 18px;
    margin-top: 10px;
    opacity: 0.8;
    line-height: 2rem;
}

.head_toe_c_two li{
    opacity: 0.7;
}
