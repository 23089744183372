.prot_four_container {
    display: flex;
    flex-direction: column;
    background-color: #222828;
    color: #dfdfdf;
    font-family: "Roboto", monospace;
}

h2 {
    opacity: 0.7;
}

h4 {
    opacity: 0.6;
    margin-bottom: 10px;
}