.prot_one_container {
    display: flex;
    flex-direction: column;
    background-color: #222828;
    color: #dfdfdf;
    font-family: "Roboto", monospace;
}

.prot_one_container ul {
    padding: 0 2%;
}

.prot_one_container p {
    line-height: 24px;
}

.prot_one_container li {
    margin-bottom: 10px;
}