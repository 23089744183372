.vrone_container {
    display: flex;
    flex-direction: column;
    background-color: #222828;
    color: #dfdfdf;
    font-family: "Roboto", monospace;

}
.trach_details_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2rem;
}

.trach_details span {
    color: #f8f8f8;
    font-weight: 700;
}

.trach_details_container h3{
    font-size: 24px;
    opacity: 1;
}

.trach_details_container h4{
    font-size: 20px;
    opacity: 1;
}

.trach_details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    
}

.trach_details ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.5rem;
    padding: 0 1rem;
}

.trach_details li{
    font-size: 18px;
    margin-top: 10px;
    opacity: 0.8;
    line-height: 2rem;
}

.trach_c_two li{
    opacity: 0.7;
}

