.dev_four_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #222828;
    color: #dfdfdf;
    font-family: "Roboto", monospace;
}

/* .dev_four_container p {
    opacity: 0.4;
} */

.dev_four_details {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1.5rem;
}

.dev_four_roles {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    
}
.dev_four_roles span{
    color: #03c988;
}
.dev_four_roles h4{
    font-size: 20px;
    color: #dfdfdf;
    opacity: 0.7;
}

.inventory_container ul{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
    padding: 0rem 2rem;
    opacity: 0.7;
}
.inventory_container li {
    font-size: 18px;
    font-weight: 500;
}
.inventory_container span{
    font-weight: 700;
}
.dev_four_container h3 {
    font-size: 28px;
    margin-bottom: 15px;
}

.dev_four_c_one p{
    font-size: 18px;
    line-height: 1.8rem;
    opacity: 0.7;
}

.dev_carousel {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap:1rem;
}

.dev_carousel ul{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;
}

.dev_carousel li {
    width: 100%;
}

