.dev_one_container {
    display: flex;
    flex-direction: column;
    background-color: #222828;
    color: #dfdfdf;
    font-family: "Roboto", monospace;
}

.tretio_details_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;

}

.tretio_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
}

.tretio_container p{
    font-size: 18px;
    line-height: 1.8rem;
    opacity: 0.7;
}

.tretio_container ul{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
    padding: 0rem 2rem;
    opacity: 0.7;
}
.tretio_container li {
    font-size: 18px;
    font-weight: 500;
    line-height: 2rem;
}
.tretio_container span{
    font-weight: 700;
}
.tretio_container h3 {
    font-size: 28px;
    margin-bottom: 15px;
}

.dev_one_c_seven {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    width: 100%;
}