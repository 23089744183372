.bg_two_container {
    display: flex;
    flex-direction: column;
    background-color: #222828;
    color: #dfdfdf;

}

.objectives_and_initial_concepts_container {
    font-family: 'Roboto', monospace;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.objectives_and_initial_concepts_container h3 {
    opacity: 0.7;
    margin-bottom: 30px;
    letter-spacing: normal;
}

.objectives_and_initial_concepts_container p {
    opacity: 0.6;
    letter-spacing: normal;
    line-height: 24px;
    margin-bottom: 20px;
}

.how_to_play_container {
    display: flex;
    flex-direction: column;

}

.how_to_play_container h2 {
    opacity: 0.7;
    margin-bottom: 20px;
}

.how_to_play_container h4 {
    opacity: 0.7;
    margin-bottom: 10px;
}

.gt_table tr{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 700px;
}

.gt_table th {
    display: flex;
    align-items: flex-start;
    width: 700px;
    padding: 1%;
    box-sizing: border-box;
    border: 1px solid #dfdfdf;
    opacity: 0.7;
    font-size: 14px;
}

.gt_table td {
    width: 700px;
    padding: 1%;
    box-sizing: border-box;
    border: 1px solid #dfdfdf;
    opacity: 0.6;
    font-size: 14px;
}
.game_pieces_container {
    font-family: 'Roboto', monospace;
}

.game_pieces_container h3 {
    opacity: 0.7;
    margin-bottom: 20px;
}
.game_pieces_container h4 {
    opacity: 0.6;
    margin-bottom: 10px;
}

.game_pieces_list {
    opacity: 0.6;
    padding: 2%;
}

.game_pieces_list li {
    margin-bottom: 10px;
}

.each_player_begins_with_list {
    opacity: 0.6;
    padding: 2%;
}
.each_player_begins_with_list li {
    margin-bottom: 10px;
}

.finaly_each_player {
    opacity: 0.7;
}

.gameplay_container {
    font-family: "Roboto", monospace;
}

.gameplay_container h3 {
    opacity: 0.7;
    margin-bottom: 20px;
}

.each_round_has_list {
    padding: 2%;
    opacity: 0.6;
}

.each_round_has_list li {
    margin-bottom: 10px;
}

.phase_details {
    display: flex;
    flex-direction: column;
}

.section_top_text {
    font-family: 'Roboto', monospace;
    opacity: 0.7;
    margin-bottom: 10px;
}

.planning_phase_container {
    font-family: 'Roboto', monospace;
}

.planning_phase_container h3 {
    opacity: 0.7;
    margin-bottom: 10px;
}

.planning_phase_container ol {
    padding: 2%;
    opacity: 0.6;
}

.planning_phase_container li {
    line-height: 22px;
}

.gt_two {
    font-family: 'Roboto', monospace;
    width: 1000px;
}

.gt_two tr{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 1000px;
    justify-content: center;
}

.gt_two th {
    display: flex;
    align-items: center;
    width: 700px;
    padding: 1%;
    box-sizing: border-box;
    border: 1px solid #dfdfdf;
    opacity: 0.7;
    font-size: 14px;
    justify-content: center;
    text-align: center;
}

.gt_two td {
    display: flex;
    align-items: center;
    height: 130px;
    width: 700px;
    padding: 1%;
    box-sizing: border-box;
    border: 1px solid #dfdfdf;
    opacity: 0.6;
    font-size: 14px;
    width: 400px;
    justify-content: center;
    text-align: center;
    line-height: 22px;
    letter-spacing: normal;
}

.spending_phase_container {
    font-family: 'Roboto', monospace;
}

.spending_phase_container h3 {
    margin-bottom: 30px;
    opacity: 0.7;
}
.bg_two_outer_ol {
    display: flex;
    flex-direction: column;
    opacity: 0.6;
    padding: 0 3% ;
    row-gap: 20px;
    margin-bottom: 40px;

}

.bg_two_inner_ol {
    display: flex;
    flex-direction: column;
    padding: 0 3%;
    
}

.bg_two_inner_ol li{
    margin-top: 10px;
}

.income_phase {
    font-family: 'Roboto', monospace;
    display: flex;
    flex-direction: column;
}

.income_phase h3 {
    opacity: 0.7;
    margin-bottom: 20px;
}

.victory_condition_container {
    font-family: 'Roboto', monospace;
    margin-bottom: 40px;
}

.victory_condition_container h3 {
    opacity: 0.7;
    margin-bottom: 30px;
}

.victory_condition_container h4{
    margin-bottom: 20px;
    opacity: 0.6;
}

.victory_condition_container ul {
    padding: 0 2%;
    margin-bottom: 20px;
}

.victory_condition_container li{
    margin-bottom: 10px;
}

.winning_cond {
    font-family: 'Roboto', monospace;
}

.winning_cond p {
    opacity: 0.6;
    line-height: 24px;
    margin-bottom: 30px;
}

.gt_two_container {
    font-family: 'Roboto', monospace;
}

.gt_two_container h3 {
    opacity: 0.7;
    margin-bottom: 20px;
}

.gt_two_container p{
    opacity: 0.6;
    margin-bottom: 30px;
}