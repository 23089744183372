.blog-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.blog-section {
    padding: 3% 7%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Roboto', monospace;
    color: rgba(255, 255, 255,0.7);
    gap: 4rem;
}
.blog-description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2rem;

}

.blog-card-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    
}
.blog-card {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 1rem;
    width: 450px;
    min-height: 400px;
    height: 550px;
    background-color: #2c3333;
    border: 1px solid #2c3333;
    border-radius: 6px;
}
.blog-card-heading {
    font-size: 24px;
    font-weight: 600;
    color: #03c988;
}
.blog-content ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
    padding-left: 1rem;
}
.blog-content  li {
    font-size: 16px;
    line-height: 26px;
}