.prot_two_container {
    display: flex;
    flex-direction: column;
    background-color: #222828;
    color: #dfdfdf;
    font-family: "Roboto", monospace;
}

.map_image_section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.map_image_section img {
    display: flex;
    align-items: center;
    height: auto;
    width: 550px;
    margin-bottom: 30px;
}

.inner_map_image_section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.prot_two_image_section {
    display: flex;
    flex-direction: row;
    column-gap: 50px;
}

.prot_two_image_section img {
    width: 500px;
    height: 400px;
    margin-bottom: 10px;
}

.prot_two_image_section div {
    display: flex;
    flex-direction: column;
    align-items: center;
}