.video-carousal-container .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    height: 400px;
    width: 500px; /* Set a height for the carousel */
  }
  
  .video-carousal-container .swiper-slide img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .video-carousal-container .swiper-button-next,
  .swiper-button-prev {
    color: yellow; /* Change to your desired color */
    font-size: 28px; /* Adjust the size if needed */
  }
  .video-carousal-container .swiper-pagination-bullet-active {
    background-color: yellow; /* Active dot color */
    opacity: 1; /* Fully opaque */
  }
  