.contact_container {
    background-color: #222828;
    padding: 3% 7%;
    display: flex;
    flex-direction: row;
    font-family: 'Roboto', monospace;
    height: auto;
}

.contact_leftside {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.contact_rightside {
    height: 550px;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    row-gap: 50px;
    background-color: #2C3333;
    padding: 4%;
    border-radius: 6px;
}

.contact_card {
    width: 400px;
    padding: 2%;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    border-radius: 6px;
    
}
.contact_text_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    height: fit-content;
}
.contact_text h3{
    font-weight: 500;
    font-size: 16px;
    color: #dfdfde;
    opacity: 0.8;
    letter-spacing: normal;
    margin-bottom: 5px;
}
.contact_text p {
    font-size: 12px;
    color: #dfdfde;
    opacity: 0.6;
    margin-bottom: 10px;
    letter-spacing: normal;
}
.contact_logo {
    display: flex;
    align-items: flex-start;
    height: fit-content;
}
.contact_subtext {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: fit-content;
    font-size: 14px;
    line-height: 24px;
    color: #dfdfde;
    opacity: 0.6;
    cursor: pointer;
}
#contact_icon {
    font-size: 16px;
    color: #03c988;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.contact_heading h3{
    font-size: 44px;
    line-height: 55px;
    color: #dfdfde;
    opacity: 0.9;
    margin-bottom: 10px;
}
.email_details {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.email_details h3{
    font-size: 56px;
    font-weight: 700;
}

.email_details span {
    color: #03c988
}

.coloured_text {
    color: #03c988;
}

.contact_form {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 30px;
}

.contact_input_filed {
    width: 600px;
    height: 30px;
    background-color: #2C3333;
    border: none;
    border-bottom: 1px solid #787878;
    outline: none;
    font-family: 'Roboto', monospace;
    color: #dfdfde;
    opacity: 0.9;
    font-size: 16px;
    resize: none;
}

.contact_input_filed::placeholder {
    font-family: 'Roboto', monospace;
    font-size: 14px;
}

.user_description_in {
    margin-top: 30px;
    height: 70px;
}

.button_container {
    height: 0px;
    display: flex;
    align-items: center;
    padding: 0;
}

.submit_button_new {
    width: 600px;
    height: 40px;
    background-color: #03c988;
    color: #2C3333;
    font-family: 'Roboto', monospace;
    font-size: 16px;
    font-weight: 500;
    border: none;
    outline: none;
    margin-top: 60px;
    border-radius: 6px;
    cursor: pointer;
    transition: all ease-in 0.2s;
}

.submit_button_new:hover {
    opacity: 0.7;
}

.s_media_icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 30px;
    padding: 12px;
}

.icon_buttons {
    background-color: #2C3333;
    opacity: 0.9;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    border: none;
    transition: all ease-in 0.2s;
    cursor: pointer;
}

.icon_buttons:hover {
    opacity: 0.7;
}

.contact_icons {
    width: 20px;
    height: 20px;
}
@media only screen and (max-width : 1080px){
    .contact_rightside {
        height: auto;
        width: 550px;
    }
    .contact_heading h3{
        font-size: 32px;
        line-height: 50px;
    }
    .contact_input_filed {
        width: 500px;
        height: 20px;
    }
    .submit_button_new {
        margin-top: -1px;
        height: 40px;
        width: 500px;
    }
}

@media only screen and (min-width : 2100px) {
    .contact_container {
        justify-content: space-between;
        padding: 2% 15%;
    }
}