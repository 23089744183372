.development_section_container {
    background-color: #2c3333;
}

.development_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3% 7%;
}

.dev_heading_section {
    width: 100%;
    font-family: 'Roboto', monospace;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.dev_section_heading_text {
    color: #dfdfdf;
    opacity: 0.9;
    font-size: 36px;

}

.dev_section_description_text {
    color: #dfdfdf;
    opacity: 0.7;
    line-height: 24px;
    width: 60%;
}


.development_container .works_card_section {
    width: 100%;
}

@media only screen and (min-width : 2560px) {
    .development_section_container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .development_container {
        padding : 3% 15%;
    }

}