.board_games_section_container {
    background-color: #2c3333;
    display: flex;
    flex-direction: column;
}

.board_games_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3% 7%;
}

.board_games_heading_section {
    width: 100%;
    font-family: 'Roboto', monospace;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.board_games_section_heading_text {
    color: #dfdfdf;
    opacity: 0.9;
    font-size: 36px;
    
}

.board_games_section_description_text {
    color: #dfdfdf;
    opacity: 0.7;
    line-height: 24px;
    width: 60%;
}
/* .board_games_section_container .works_card {
    height: 450px;
    margin-bottom: 40px;
} */
/* .board_games_section_container .w_card_image_section {
    height: 350px;
}
.board_games_section_container .w_card_image_section img {
    height: 300px;
    object-fit: cover;
} */

.works_card p {
    font-size: 16px;
    font-family: 'Roboto', monospace;
}
@media only screen and (min-width: 2560px) {
    .board_games_container {
        padding: 2% 15%;
    }

}