.bg_four_container {
    display: flex;
    flex-direction: column;
    background-color: #222828;
    color: #dfdfdf;
}
.design_goal_section{
    font-family: 'Roboto', monospace;
}

.design_goal_section h3 {
    opacity: 0.7;
    margin-bottom: 30px;
}

.design_goal_section p {
    opacity: 0.6;
    line-height: 24px;
    margin-bottom: 30px;
}

.material_and_dimentions_section{
    font-family: 'Roboto', monospace;
}

.material_and_dimentions_section h3 {
    opacity: 0.7;
    margin-bottom: 30px;
}

.material_and_dimentions_section p {
    opacity: 0.6;
    line-height: 24px;
    margin-bottom: 30px;
}