.prot_three_container {
    display: flex;
    flex-direction: column;
    background-color: #222828;
    color: #dfdfdf;
    font-family: "Roboto", monospace;
}

.prot_three_c_thirteen img{
    width: 150px;
    height: auto;
    margin-bottom: 10px;
}

.prot_three_c_twelve img {
    width: 150px;
    height: auto;
}

.prot_three_c_fourteen img{
    width: 150px;
    height: auto;
}

.prot_three_c_nineteen img {
    width: 100%;
    height: auto;
}