.works_main_container {
    background-color: #2C3333;
    padding: 3% 7%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Roboto', monospace;
}

.works_nav_section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 30px;
}

.works_nav_button {
    width: 240px;
    height: 200px;
    background-color: #3a4444;
    outline: none;
    border-radius: 6px;
    border: none;
    padding: 2%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: center;
    transition: all ease-in 0.3s;
    cursor: pointer;
    justify-content: space-between;
}

/* .works_nav_button:hover {
    opacity: 0.8;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px rgab(0, 0, 0, 0.4);
    transition: all ease-in 0.3s;
} */

.works_nav_button:hover {
    box-sizing: border-box;
    box-shadow: 0px 0px 21px 4px rgba(0, 0, 0, 0.6);
    transition: all ease-in 0.3s;

}

.works_button_title {
    font-family: 'Roboto', monospace;
    font-size: 20px;
    font-weight: 500;
    color: #dfdfdf;
    letter-spacing: normal;
}

.works_image_container {
    display: flex;
    justify-content: center;
    height: auto;
    width: 190px;
    transition: all ease-in 0.3s;
    box-sizing: border-box;
    border-radius: 6px;
}

.works_image {
    display: flex;
    align-items: center;
    width: 120px;
    height: auto;
    border-radius: 6px;
}

.board_image {
    height: 120px;
    width: 190px;
}

.design_card_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;

}

.w_card_section_title_text {
    font-family: "Roboto", monospace;
    font-size: 20px;
    font-weight: 400;
    color: #dfdfdf;
    margin-bottom: 20px;
}

.works_card_section {
    margin-top: 100px;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;

}

.works_card {
    width: 450px;
    height: 450px;
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', monospace;
    row-gap: 1rem;
    border-radius: 6px;
    transition: all ease-in 0.3s;
    cursor: pointer;
}

.works_card:hover img {
    box-sizing: border-box;
    box-shadow: 0px 0px 21px 4px rgba(0, 0, 0, 0.6);
    transition: all ease-in 0.3s;
}

.w_card_image_section {
    width: 300px;
    height: 250px;
    object-fit: cover;
}

.w_card_image_section img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
}

.w_card_text_section {
    display: flex;
    flex-direction: column;
    gap:1rem;
    
}

.w_card_type {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
}

.w_card_title {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.w_card_date_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    align-items: flex-start;
    color: #dfdfdf;
    opacity: 0.7;
    font-weight: 400;
}

.w_card_created_at h5{
    color: #03c988;
}
.w_card_project_heading {
    color: #dfdfdf;
    opacity: 0.8;
    font-size: 16px;
    letter-spacing: normal;
}
.w_card_project_description {
    text-align: justify;
    font-size: 14px;
    width: 450px;
    color: #dfdfdf;
    opacity: 0.7;
    line-height: 20px;
}
@media only screen and (max-width : 1080px) {
.works_nav_button {
    width: 200px;
    height: 200px;
}
.works_button_title {
    font-size: 14px;
}
.works_main_container .works_card_section {
    margin-top: -50px;
}
.works_image_container {
    width: 120px;
}
.works_image {
    object-fit: contain;
}
.w_card_image_section{
    width: max-content;
}
.works_card {
    width: 400px;
}
.w_card_image_section img {
    width: 400px;
    
}
.w_card_project_description {
    width: 400px;
}

}

@media only screen and (min-width : 1220px) {
    .design_card_container {
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .works_nav_section {
        row-gap: 30px;
    }
    .works_card {
        width: max-content;
        height: 480px;
        
    }
    .w_card_image_section {
        height: 250px;
        width: 500px;
    }
    .w_card_image_section img{
        height: 250px;
        width: 500px;
    }
    .w_card_text_section {
        width: 500px;
    }
    .w_card_project_description {
        width: 500px;
    }
}

/* @media only screen and (min-width : 1920px) {
    .works_nav_button {
        width: 300px;
    }
    .w_card_image_section {
        height: 400px;
        width: 450px;
    }
    .w_card_image_section img{
        height: 290px;
        width: 450px;
    }
    .w_card_text_section {
        width: 450px;
    }
    .w_card_title p {
        font-size: 18px;
        line-height: 24px;
        opacity: 0.4;
        width: 100%;
    }
} */


@media only screen and (min-width : 2560px) {
    .works_main_container {
        padding: 2% 15%;
    }

    .works_nav_button {
        width: 300px;
        height: auto;
    }
    .works_card {
        height: 600px;
    }
    .w_card_image_section {
        height: 400px;
        width: 550px;
    }
    .w_card_image_section img{
        height: 400px;
        width: 550px;
    }
    .w_card_text_section {
        width: 550px;
    }
    .w_card_title h4 {
        font-size: 28px;
    }
    .w_card_title p {
        font-size: 18px;
        line-height: 24px;
        opacity: 0.4;
        width: 100%;
    }
}