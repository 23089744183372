.dev_seven_container {
    display: flex;
    flex-direction: column;
    background-color: #222828;
    color: #dfdfdf;
    font-family: "Roboto", monospace;
    column-gap: 2rem;
}
.dev_seven_container .main_section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4rem;
}
.dev-sev-intro {
    display: flex;
    flex-direction: column;
    
    gap: 2rem;
}
.dev-sev-intro p {
    font-size: 18px;
    line-height: 26px;
}
.module-card {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    
}

.module-card-player {
    display: flex;
    flex: 1;
    height: 400px;
    width: 400px;

}

.module-description {
    display: flex;
    flex-direction: column;
    flex: 1;
    row-gap: 2rem;
    background-color: #2a2c2c;
    padding: 1rem;
    border: 1px solid #2a2c2c;
    border-radius: 6px;
}

.module-description ul {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    max-width: 900px;
    padding-left: 1rem;
}

.module-card-yt-player {
    width: 100%;
    height: 100%;
}

.module-card h3 {
    font-size: 22px;
    margin-bottom: 15px;
}

.module-card li {
    font-size: 14px;
    line-height: 22px;
}