.nav_bar_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2% 7%;
    align-items: center;
    font-family: 'Roboto Mono', monospace;
    background-color: #2C3333;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
}

.logo_section a{
    width: 300px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-family: 'Poppins', sans-serif;
    color: #d4d4d4;
    letter-spacing: normal;
    font-weight: 300;
    text-decoration: none;
    word-spacing: 5px;
}
.span_class {
    margin-left: -2px;
    font-family: 'Poppins', sans-serif;
    opacity: 0.7;
    letter-spacing: normal;
    font-weight: 300;
}

.nav_bar {
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    height: 50%;
}

.nav_items {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px;
    align-items: center;
    text-decoration: none;
    width: 300px;
    color: #DFDFDE;
    font-size: 12px;
    letter-spacing: normal;
    cursor: pointer;
}

.nav_link_btn {
    display: flex;
    align-items: center;
    text-decoration: none; 
    color: #dfdfde;
    font-size: 14px;
    opacity: 0.8;

}
.nav_link_btn:hover {
    color: #03c988 !important;
}

.nav_list_item{
    list-style-type: none;
    display: flex;
    height: 30px;
    width: 90px;
    column-gap: 5px;
    border: 1px solid #2C3333;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    transition: all ease-in 0.2s;
}

.nav_list_item:hover {
    /* background-color: #03C988; */
    color: #03c988;

}

.nav_icon {
    width: 20px;
    height: 20px;
}

#email_icon {
    display: flex;
    align-items: center;
    font-size: 16px;
}

@media only screen and (max-width : 720px) {
    .logo_section {
        font-size: 16px;
    }
    .span_class {
        margin-left: 2px;
    }
    .nav_items {
        width: 230px;
    }
    .nav_list_item {
        width: 60px;
        height: 20px;
    }
    .nav_icon {
        width: 15px;
        height: 15px;
    }
}


@media only screen and (max-width : 850px) {
    .logo_section {
        font-size: 18px;
    }
}


@media only screen and (min-width : 2100px) {
    .nav_bar_container {
        justify-content: space-between;
        padding: 2% 15%;
    }
}

@media only screen and (min-width: 2560px) {
    .nav_bar_container {
        justify-content: space-between;
        padding: 2% 15%;
    }
}